 
<template>
	<b-table responsive :items="queryData" :fields="fields" class="mb-0">
		<template #cell(Phone)="data">
			<span class="text-nowrap">
				{{ data.value }}
			</span>
		</template>

		<!-- Optional default data cell scoped slot -->
		<template #cell()="data">
			{{ data.value }}
		</template>
	</b-table>
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
	components: {
		BTable,
	},
	data() {
		return {
			fields: [
				'id',
				'person_id',
				'camera_img_url',
				'liveness_status',
			],
			queryData: []
		}
	},
	created() {
		this.$http
			.get(
				'/api/liveness/?end_date=2022-09-08T00%3A00%3A00.00Z&error_type=1&start_date=2021-09-08T00%3A00%3A00.00Z',
			)
			.then(res => {
				this.queryData = res.data.results
			})
	},
}
</script>
 