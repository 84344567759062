import Vue from 'vue'
import { computed, reactive, ref } from '@vue/composition-api'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const APIRoot = (process.env.VUE_APP_BACKEND || '') + '/api/'

class APIMethod {
	constructor(def = {}) {
		def = Object.assign(
			{
				method: 'GET',
				path: '',
				error: {
					message() {
						return 'Ошибка запроса: ' + this.path
					},
					handler: undefined,
				},
			},
			def,
		)
		this.method = def.method.toUpperCase()
		this.type = def.type.toLowerCase()
		const split = def.path.split(':')
		this.path = split[0]
		if (split[1] !== undefined) this.id = split[1]
		this.errorMessage = def.error.message
		if (def.error.handler) this.errorHandler = def.errorHandler
		this.send = this.create()
	}

	create() {
		return async params => {
			if (this.id !== undefined) {
				var id = params[this.id]
				if (this.method == 'GET' || this.method == 'POST') delete params[this.id]
			}
			try {
				let req
				if (this.method == 'GET') {
					req = await axios[this.method.toLowerCase()](APIRoot + this.path + (id === undefined ? '' : id), {
						params,
					})
				} else {
					req = await axios[this.method.toLowerCase()](
						APIRoot + this.path + (id === undefined ? '' : id),
						params,
					)
				}
				if (req.status > 303) {
					this.errorHandler(req.data)
					return null
				}
				return req
			} catch (e) {
				this.errorHandler(e)
			}
			return null
		}
	}

	errorHandler(e) {
		console.error(this.errorMessage(), e)
		Vue.$toast({
			component: ToastificationContent,
			props: {
				title: this.errorMessage(),
				icon: 'AlertTriangleIcon',
				variant: 'danger',
			},
		})
	}

	async send() {
		throw new Error('NotImplementedError')
	}
}

class APINode {
	constructor(methods, flags) {
		this.flags = Object.assign(
			{
				paginated: false,
				orderable: false,
				listable: false,
			},
			flags,
		)
		methods.forEach(endpoint => {
			this[`_${endpoint.type}`] = endpoint
		})
		if (this._list) this.flags.listable = true
	}

	data = reactive({
		dict: {},
		items: [],
		pagination: {
			from: 0,
			to: 0,
			of: 0,
			per: 0,
		},
	})

	async list(params) {
		let req = await this._list.send(params)
		if (req) {
			if (this.flags.paginated) {
				if (req.data.count) {
					this.data.items = req.data.results
					this.data.pagination.from = req.data.page_size * (req.data.page_number - 1) + 1
					this.data.pagination.to = Math.min(
						req.data.page_size * req.data.page_number,
						req.data.results.length,
					)
					this.data.pagination.of = req.data.count
					this.data.pagination.per = req.data.page_size
				} else {
					this.data.items = req.data.items
					if (req.data.items) {
						this.data.pagination.from = req.data.size * (req.data.page - 1) + 1
						this.data.pagination.to = Math.min(req.data.size * req.data.page, req.data.items.length)
						this.data.pagination.of = req.data.total
						this.data.pagination.per = req.data.size
					} else {
						this.data.pagination.to = this.data.pagination.of = this.data.pagination.per = this.data.pagination.from = 0
					}
				}
			} else {
				this.data.items = req.data
			}
			if (this.flags.orderable) {
				this.data.items = this.data.items?.sort((a, b) => b.id - a.id)
			}
			return req
		}
	}

	async get(params) {
		const id = params[this._get.id]
		let req = await this._get.send(params)
		if (req) {
			this.data.dict[id] = req.data
			return req
		}
	}

	create(params) {
		return this._create.send(params)
	}
	patch(params) {
		return this._patch.send(params)
	}
	delete(params) {
		return this._delete.send(params)
	}
}

export const Oneton = new APINode([new APIMethod({ type: 'list', method: 'GET', path: '1-to-n' })], {
	paginated: true,
	orderable: true,
})

export const BaseOnetoN = new APINode(
	[
		new APIMethod({ type: 'list', method: 'GET', path: '1-to-n/search-records' }),
		new APIMethod({ type: 'create', method: 'POST', path: '1-to-n/add/' }),
	],
	{
		paginated: true,
	},
)
