var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticStyle:{"padding-bottom":"1em"}},[_c('b-col',{attrs:{"lg":"4"}},[_c('b-tabs',{attrs:{"justified":""},model:{value:(_vm.selectedTabIndex),callback:function ($$v) {_vm.selectedTabIndex=$$v},expression:"selectedTabIndex"}},[_c('b-tab',{attrs:{"title":"All time","active":""}}),_c('b-tab',{attrs:{"title":"добавления"}}),_c('b-tab',{attrs:{"title":"обновления"}})],1)],1)],1),(_vm.selectedTabIndex > 0)?_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('date-tabs')],1)],1):_vm._e(),_c('b-card',[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"lg":"3"}},[_c('label',[_vm._v("Person ID")]),_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Номер документа"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (val) {
								_vm.personID = val.target.value
								_vm.tableParams.page = 1
							}).apply(null, arguments)}},model:{value:(_vm.personID_),callback:function ($$v) {_vm.personID_=$$v},expression:"personID_"}}),(_vm.personID_)?_c('b-input-group-append',{staticStyle:{"cursor":"pointer"},attrs:{"is-text":""},on:{"click":function () {
								_vm.personID_ = ''
								_vm.personID = ''
							}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"}})],1):_vm._e()],1)],1),_c('b-col',[_c('label',[_vm._v(" ")]),_c('b-input-group',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.isAddNewUserSidebarActive = true}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Создать")])])],1),_c('sidebar-create-new',{attrs:{"visible":_vm.isAddNewUserSidebarActive,"fields":[
						{
							key: 'id_photo',
							label: 'Фото',
							filter: 'image',
							base64: true,
						},
						{
							key: 'person_id',
							label: 'Имя',
							filter: 'string',
						},
						{
							key: 'group_id',
							label: 'Группа',
							filter: 'option',
							idField: 'id',
							valueField: 'title',
							filterOptions: [
								{ id: 777, title: 'Группа 777' },
								{ id: 888, title: 'Группа 888' } ],
						} ],"api":_vm.BaseOnetoN,"data":{}},on:{"update:visible":function($event){_vm.isAddNewUserSidebarActive=$event},"refresh":function () {
							_vm.ctx.refs.table.fetchList()
						}}})],1)],1),_c('editable-table',{ref:"table",attrs:{"api":_vm.BaseOnetoN,"fields":_vm.fields,"params":_vm.tableParams,"on-click":_vm.onClick},scopedSlots:_vm._u([{key:"cell(created)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(new Date(data.item.created).toLocaleString())+" ")])]}},{key:"cell(modified)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(new Date(data.item.modified).toLocaleString())+" ")])]}},{key:"cell(photo)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"}},[[(data.item.minio_path)?_c('b-link',{attrs:{"href":(data.item.minio_path || '').replace('http://minio:9000', _vm.VUE_APP_MINIO || ''),"target":"_blank"}},[_c('b-avatar',{attrs:{"size":"32","src":(data.item.cropped_minio_path || data.item.minio_path || '').replace(
										'http://minio:9000',
										_vm.VUE_APP_MINIO || ''
									),"variant":"light-primary"}})],1):_vm._e()]],2)]}}])})],1),_c('b-modal',{ref:"modal",attrs:{"id":"modal-lg","ok-only":"","ok-title":"Close","centered":"","size":"lg","title":"Информация"}},[_c('div',{staticClass:"employee-task d-flex justify-content-between align-items-center mb-1"},[(_vm.requestData)?_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-avatar',{attrs:{"rounded":"","size":"100","src":(_vm.requestData.photo.minio_path || '').replace('http://minio:9000', _vm.VUE_APP_MINIO || '')}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_c('b-badge',[_vm._v(" "+_vm._s(_vm.requestData.id)+" ")])],1),_c('div',[_vm._v(_vm._s(new Date(_vm.requestData.created).toLocaleString()))]),_c('div',[_vm._v(" "+_vm._s(_vm.requestData.response && _vm.requestData.response.data && _vm.requestData.response.data.results && _vm.requestData.response.data.results.length ? Math.max.apply(Math, _vm.requestData.response.data.results.map(function (r) { return r.recognitionValue; })) + '%' : '-')+" ")])])],1):_vm._e()],1),_vm._l((_vm.rowData),function(row){return _c('div',{key:row.personId,staticClass:"employee-task d-flex justify-content-between align-items-center mb-1"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-link',{attrs:{"href":(row.minio_path || '').replace('http://minio:9000', _vm.VUE_APP_MINIO || ''),"target":"_blank"}},[_c('b-avatar',{attrs:{"rounded":"","size":"64","src":(row.cropped_minio_path || '').replace('http://minio:9000', _vm.VUE_APP_MINIO || '')}})],1)],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(row.personId)+" ")]),_c('span',[_vm._v(_vm._s(row.recognitionValue)+"%")])])],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }