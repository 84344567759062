function number(value) {
	return value.replace(/[^0-9]/g, "");
}

function alphanumeric(value) {
	return value.replace(/[^a-zA-Z0-9]/g, "");
}

function symbolic(value) {
	return value.replace(/[^a-zA-Z0-9_\-./]/g, "");
}

function email(value) {
	return value.replace(/[^a-zA-Z0-9@.+_]/g, "");
}

function float(value) {
	return value.replace(/[^0-9.]/g, "");
}

function phone(value) {
	return value.replace(/[^0-9+\-\(\)]/g, "");
}

function time(value) {
	return value.replace(/[^0-9\:]/g, "");
}

export default {
	number,
	symbolic,
	alphanumeric,
	email,
	float,
	phone,
	time
}