<template>
	<component
		:is="modal ? 'b-modal' : 'b-sidebar'"
		class="create-sidebar"
		:visible="visible"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		:body-class="modal ? 'p-0' : ''"
		:footer-class="modal ? 'd-none' : ''"
		right
		bg-variant="white"
		@hidden="closeSidebar(undefined, false)"
		width="max(50%, 55em)"
		no-close-on-esc
		:no-close-on-backdrop="modal ? false : true"
		:id="uuid"
		@hide="onHide($event, refFormObserver.flags.dirty)"
	>
		<template>
			<div
				:class="{
					'lookup-host': true,
					'has-items': lookup.results.length,
				}"
				v-if="lookup.enabled"
			>
				<h5 style="color: white; text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5)" v-if="lookup.results.length">
					Результаты поиска
				</h5>
				<template v-for="result in lookup.results">
					<component
						v-if="
							localData[lookup.field.patchKey]
								? result[lookup.field.idField] != localData[lookup.field.patchKey]
								: true
						"
						:key="result.id"
						:is="lookup.field.lookup.widget"
						:value="result"
						@click="data => applyMask(data)"
						:clearable="false"
					>
					</component>
				</template>
				<span v-if="lookup.loading" class="loader">
					<b-spinner variant="light" />
				</span>
			</div>
			<div
				class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
				v-if="!modal"
			>
				<h5 class="mb-0">{{ fresh ? 'Создать' : 'Редактирование' }}</h5>
				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click.prevent="closeSidebarActive(refFormObserver.flags.dirty)"
				/>
			</div>

			<validation-observer #default="{ handleSubmit }" ref="refFormObserver" :key="visible">
				<b-form
					class="p-2"
					@submit.prevent="
						() => {
							handleSubmit(onSubmit)
						}
					"
					@reset.prevent="resetForm"
				>
					<div
						v-for="field in schema"
						:key="field.key"
						class="field-host"
						:style="
							(() => {
								if (field.group == undefined) return ''
								const scan = schema.filter(fld => fld.group == field.group)
								const num = scan.length
								const margin = 1
								const neg = (margin * (num - 1)) / num
								return `display: inline-block; width: calc(${100 / num}% - ${neg}rem); margin-left: ${
									field != scan[0] ? margin : 0
								}rem; vertical-align: top;`
							})()
						"
						:class="{ shrink: !!field.shrink }"
						v-show="field.edit === false ? fresh : true"
					>
						<slot
							:name="'sidebar-' + field.key"
							:field="field"
							:fields="schema"
							:data="localData"
							:visible="visible"
							:initial="initial"
						>
							<!-- special field type - lookup of existing entity -->
							<template v-if="field.filter == 'entity_lookup'">
								<typed-field
									style="margin-bottom: 1em; display: block"
									v-if="localData[field.patchKey]"
									v-model="localData[field.key]"
									:field="field"
									:validation="true"
									:fresh="fresh"
									@clear="lookup.clear"
									:clearable="initial[field.patchKey] == undefined"
								/>
							</template>
							<template v-else-if="fieldVisibility(field, localData)">
								<label v-if="field.filter != 'boolean' && field.filter !== true && !field.noLabel">{{
									field.label
								}}</label>
								<b-form-group :name="field.label" :label-for="field.label" v-if="field.filter !== true">
									<typed-field
										v-model="localData[field.key]"
										:field="field"
										:validation="maskData[field.key] === undefined"
										:fresh="fresh"
										@input="input"
									/>
								</b-form-group>
							</template>
						</slot>
						<slot
							:name="'sidebar-mask-' + field.key"
							:field="field"
							:fields="schema"
							:data="localData"
							:visible="visible"
							:initial="initial"
						>
							<template v-if="fieldVisibility(field, localData) && maskData[field.key]">
								<b-form-group
									:name="field.label"
									:label-for="field.label"
									v-if="field.filter !== true"
									style="position: absolute; bottom: 0; margin-bottom: 0; width: 100%"
								>
									<typed-field
										v-model="maskData[field.key]"
										:field="field"
										:validation="true"
										:fresh="fresh"
										@input="input"
									/>
								</b-form-group>
							</template>
						</slot>
						<slot
							:name="'sidebar-logic-' + field.key"
							:field="field"
							:fields="schema"
							:data="localData"
							:visible="visible"
							:initial="initial"
						>
						</slot>
					</div>
					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-modal
							ref="myModalSidebar"
							title="Подтверждение"
							@ok="closeSidebar(true, false)"
							ok-title="Да"
							cancel-title="Отмена"
							centered
						>
							<b-card-text>У вас есть несохраненные изменения. Закрыть?</b-card-text>
						</b-modal>
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							:variant="fresh ? 'success' : 'primary'"
							class="mr-2"
							type="submit"
							:disabled="requesting"
						>
							<b-spinner v-if="requesting" small class="mr-1" />
							<span class="align-middle">{{ fresh ? 'Создать' : 'Сохранить' }}</span>
						</b-button>

						<b-dropdown
							class="mr-3"
							variant="outline-danger"
							no-caret
							v-if="!fresh && (can ? can.delete : true)"
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
						>
							<template #button-content> Удалить </template>

							<b-dropdown-item variant="danger" @click="onDelete">
								<span class="align-middle ml-50">Удалить</span>
							</b-dropdown-item>
							<b-dropdown-item variant="secondary">
								<span class="align-middle ml-50">Отмена</span>
							</b-dropdown-item>
						</b-dropdown>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click.prevent="closeSidebarActive(refFormObserver.flags.dirty)"
						>
							Отмена
						</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</component>
</template>

<script>
	import {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		BButton,
		BSpinner,
		BFormCheckbox,
		BDropdown,
		BDropdownItem,
		BModal,
	} from 'bootstrap-vue'
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import { ref, toRefs, watch, computed, onMounted, onBeforeMount, reactive } from '@vue/composition-api'
	import { required, alphaNum, email } from '@validations'
	import formValidation from '@core/comp-functions/forms/form-validation'
	import Ripple from 'vue-ripple-directive'
	import jwt from '@/auth/jwt/useJwt'
	const axios = jwt.axiosIns
	import generateUUID from '@/libs/uuid'
	export default {
		components: {
			BSidebar,
			BForm,
			BFormGroup,
			BFormInput,
			BFormInvalidFeedback,
			BButton,
			BSpinner,
			BFormCheckbox,
			BDropdown,
			BDropdownItem,
			BModal,
			BSpinner,
			TypedField: () => import('./TypedField.vue'),

			// Form Validation
			ValidationProvider,
			ValidationObserver,
		},
		directives: {
			Ripple,
		},
		props: {
			visible: {
				type: Boolean,
				required: false,
				default: false,
			},
			fields: {
				type: Array,
				required: true,
			},
			data: {
				type: Object,
			},
			staticData: {
				type: Object,
			},
			item: {
				type: Object,
				required: false,
			},
			api: {
				type: Object,
				required: true,
			},
			modal: {
				type: Boolean,
			},
			fieldVisibility: {
				type: Function,
				default: () => true,
			},
			preSubmit: {
				type: Function,
				default: (a, b, c, d) => a,
			},
		},
		setup(props, { emit, refs }) {
			const { data, fields, staticData, modal, visible } = toRefs(props)
			const { api } = props
			const schema = ref([...fields.value.filter(field => field.filter && !field.hidden)])

			if (api) {
				var can = {
					list: api?.permissions?.list() || true,
					patch: api?.permissions?.patch(),
					delete: api?.permissions?.delete(),
					create: api?.permissions?.create() || true,
				}
			}

			const uuid = '_' + generateUUID()

			function createBlank(fields) {
				const blank = Object.fromEntries(
					fields.map(field => {
						if (field.default) return [field.key, field.default]
						if (typeof field.filter == 'object') return [field.key, createBlank(field.filter)]
						if (field.filter == 'boolean') return [field.key, false]
						//wacko behavior for entity_lookup initial data because we aren't actually editing the main key and only have the id in patchkey, compared to option logic where it has a full object
						if (field.filter == 'entity_lookup') return [field.patchKey, undefined]
						return [field.key, undefined]
					}),
				)
				if (staticData.value) {
					return Object.assign(blank, JSON.parse(JSON.stringify(staticData.value)))
				} else {
					return blank
				}
			}

			const localData = ref(createBlank(schema.value))
			const initial = JSON.parse(JSON.stringify(localData.value))
			const fresh = computed(() => {
				return !data.value || Object.keys(data.value).length == 0
			})
			const myModalSidebar = ref(null)

			watch(data, (a, b) => {
				if (!data.value || Object.keys(data.value).length == 0) {
					localData.value = createBlank(schema.value)
				} else {
					const blank = createBlank(schema.value)
					const cleanData = JSON.parse(JSON.stringify(data.value))
					const primary = fields.value.find(field => field.primary)
					if (primary) blank[primary.key] = cleanData[primary.key]

					for (let key in blank) {
						blank[key] = cleanData[key]
					}

					//gather baked-in fields for the correct "chosen entity" display if its id is present
					if (lookup.enabled && blank[lookup.field.patchKey]) {
						blank[lookup.field.key] = Object.fromEntries(lookup.field.lookup.maskFields.map(f => [f, blank[f]]))
						blank[lookup.field.key][lookup.field.idField] = blank[lookup.field.patchKey]
					}
					localData.value = blank
				}
				Object.assign(initial, localData.value)
				console.log(initial)
				setTimeout(() => {
					if (!refFormObserver.value) return
					refFormObserver.value.reset()
					refFormObserver.value.validate()
				}, 20)
			})

			watch(visible, () => {
				setTimeout(() => refs?.refFormObserver?.reset(), 30)
			})
			// const userData = ref(JSON.parse(JSON.stringify(fields)))
			const reset = () => {
				localData.value = initial
				lookup.results = []
				maskData.value = {}
			}

			const requesting = ref(false)
			const allValid = computed(() => {
				return refFormObserver?.value?.flags?.valid == undefined ? true : refFormObserver?.value?.flags?.valid
			})

			const onSubmit = async () => {
				// if (
				// 	data.value.shift?.id !== localData.value.shift &&
				// 	typeof localData.value.shift !== "object"
				// ) {
				// 	emit("sideNotification", true);
				// }

				let request

				const deferredSubmissions = []

				if (api.flags.form) {
					var submitData = { ...localData.value }
					const form = new FormData()
					for (let key in submitData) {
						const field = fields.value.find(field => field.key == key)
						if (field.unwrap && typeof submitData[key] == 'object') {
							var value = submitData[key][field.idField]
						} else {
							value = submitData[key]
						}

						if (field.patchKey) {
							submitData[field.patchKey] = value
							delete submitData[key]
							key = field.patchKey
						}
						if (value instanceof File) {
							form.append(key, value, value.name)
						} else {
							if (value !== undefined && value !== null) form.append(key, value)
						}
					}
					submitData = form
				} else {
					submitData = JSON.parse(JSON.stringify(localData.value))
					const maskSubmitData = JSON.parse(JSON.stringify(maskData.value))
					for (let key in maskSubmitData) {
						submitData[key] = maskSubmitData[key]
					}
					for (let key in submitData) {
						const field = fields.value.find(field => field.key == key || field.patchKey == key)

						if (field.unwrap && typeof submitData[key] == 'object' && submitData[key] !== null) {
							var value = submitData[key][field.idField]
						} else {
							value = submitData[key]
						}

						if (field.patchKey) {
							submitData[field.patchKey] = value
							delete submitData[key]
						}

						if (field.uploadKey) {
							if (localData.value[key] instanceof File || localData.value[key] instanceof Blob) {
								deferredSubmissions.push({
									key: key,
									uploadKey: field.uploadKey,
									uploadPath: field.uploadPath,
									uploadVerify: field.uploadVerify,
									body: localData.value[key],
								})
							}
							delete submitData[key]
						}
					}
				}
				try {
					for (let i = 0; i < deferredSubmissions.length; i++) {
						if (!deferredSubmissions[i].uploadVerify) continue
						const sub = deferredSubmissions[i]
						const form = new FormData()
						form.append(sub.uploadKey, sub.body, sub.body instanceof Blob ? 'upload.jpg' : undefined)
						await axios.post(sub.uploadVerify, form)
					}

					requesting.value = true
					console.log(props.preSubmit)
					submitData = props.preSubmit(submitData, fresh.value)
					if (fresh.value) {
						request = await api.create(submitData)
						var id = request.data[api.idKey]
					} else {
						request = await api.patch(submitData)
						id = localData.value[api.idKey]
					}

					for (let i = 0; i < deferredSubmissions.length; i++) {
						const sub = deferredSubmissions[i]
						const path = api.getResourceURL(id)
						const form = new FormData()
						form.append(sub.uploadKey, sub.body, sub.body instanceof Blob ? 'upload.jpg' : undefined)
						await axios.post(path + sub.uploadPath, form)
					}

					closeSidebar(true, true, 'save')
				} catch (e) {
					console.error(e)
					requesting.value = false
					if (e?.response?.data) refFormObserver?.value.setErrors(e?.response?.data)
					// refFormObserver.value.setErrors(e.response.data);
				}
			}

			const onDelete = async () => {
				requesting.value = true
				await api.delete(initial.id)
				requesting.value = false
				closeSidebar(undefined, true, 'delete')
			}

			const { refFormObserver, getValidationState, resetForm } = formValidation(reset)

			function closeSidebarActive(isDirty) {
				if (isDirty) {
					myModalSidebar.value.show()
				} else {
					closeSidebar()
				}
			}

			const closeSidebar = (e, update) => {
				requesting.value = false
				emit('update:visible', false)
				if (!modal.value) resetForm()
				schema.value = [...fields.value.filter(field => field.filter && !field.hidden)]
				if (update) emit('refresh', true)
				lookup.results = []
				maskData.value = {}
			}

			function clickOnBackdropclose() {
				let el_backdrop = document.querySelector(`#${uuid} ~ .b-sidebar-backdrop`)
				if (!el_backdrop) return
				el_backdrop.addEventListener('click', function (event) {
					closeSidebarActive(refFormObserver.value.flags.dirty)
				})
			}

			onMounted(() => {
				clickOnBackdropclose()
			})

			function onHide(e, isDirty) {
				if (e.trigger && isDirty) {
					e.preventDefault()
				}
				closeSidebarActive(isDirty)
			}

			// FEATURE: lookup of entity using existing field data.
			const lookup = reactive({
				enabled: fields.value.some(f => f.filter == 'entity_lookup'),
				field: fields.value.find(f => f.filter == 'entity_lookup'),
				loading: false,
				results: [],
				find: async () => {
					if (!lookup.enabled) return
					if (!visible.value) return
					if (initial[lookup.field.patchKey]) return
					lookup.loading = true
					const params = Object.fromEntries(
						lookup.field.lookup.queryFields
							.map(f => {
								return [f, localData.value[f]]
							})
							.filter(([key, val]) => val != undefined),
					)
					//caution: falsy int values will get shredded

					lookup.results = await lookup.field.lookup.search(undefined, params)
					lookup.loading = false
				},
				clear: () => {
					localData.value[lookup.field.key] = undefined
					localData.value[lookup.field.patchKey] = undefined
					maskData.value = {}
				},
			})

			const maskData = ref({})

			//creates overlay fields for the existing fields based on the chosen data, the entity id will be unwrapped at submit stage.
			function applyMask(data) {
				if (!lookup.enabled) return
				maskData.value = {}
				lookup.field.lookup.maskFields.forEach(f => {
					maskData.value[f] = data[f]
				})
				localData.value[lookup.field.key] = data
				console.log(data, localData.value)
				localData.value[lookup.field.patchKey] = data[lookup.field.idField]
				localData.value = { ...localData.value }
			}

			function input() {
				lookup.find()
			}

			return {
				uuid,
				onHide,

				onSubmit,
				onDelete,

				refFormObserver,
				getValidationState,
				resetForm,
				closeSidebar,
				myModalSidebar,
				closeSidebarActive,

				localData,
				initial,

				required,
				alphaNum,
				email,

				schema,

				fresh,
				requesting,
				allValid,

				refValidator: ref(null),

				console,
				can,

				lookup,
				input,
				maskData,
				applyMask,
			}
		},
	}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.create-sidebar .vs__dropdown-menu {
	max-height: 200px !important;
}
</style>

<style scoped>
.lookup-host {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 1em;
	padding: 1em;
	transform: translateX(-100%);
	position: absolute;
	min-width: 28em;
	pointer-events: none;
}
.lookup-host > div {
	animation: fade-in 0.2s ease-in-out;
	pointer-events: all;
}
.has-items {
	animation: fade-in 0.2s ease-in-out;
}
.has-items:before {
	content: '';
	top: 0;
	left: 0;
	right: 0;
	height: 18em;
	display: block;
	position: absolute;
	background: linear-gradient(30deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
	z-index: -1;
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.field-host {
	position: relative;
}
.loader {
	opacity: 0;
	animation: 0.7s fade-in 0.3s ease-in-out;
	animation-fill-mode: forwards;
}
</style>
